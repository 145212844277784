<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="_cancel">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <DialogOverlay
       class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <!-- This element is to trick the browser into centering the modal contents. -->
     <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
      <div
       class="sm:my-8 sm:align-middle sm:max-w-5xl sm:h-auto sm:w-full sm:p-6 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all">
       <div class="sm:flex sm:items-start items-center gap-2">
        <div
         class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 sm:mx-0 sm:h-10 sm:w-10">
         <font-awesome-icon
          icon="question"
          class="h-5 w-5 text-gray-100"
          aria-hidden="true" />
        </div>
        <div
         class="text-center justify-center items-center mx-auto text-gray-600">
         <!-- <div class="items-center">text</div> -->
         <span class="flex justify-center font-bold gap-1 text-xl"
          >Visualisez les statistiques
          <span class="text-blue-600 text-xl"
           ><a href="/dashboard">globales</a></span
          >
          de votre entreprise.
         </span>

         <span class="flex justify-center gap-1 text-lg"
          >Pour des analyses détaillées par numéro, service ou utilisateur,
          découvrez
          <span class="text-md">
           <a href="/expert-statistics/home">
            Expert Stats
            <font-awesome-icon icon="chart-pie" class="icon" />
           </a>
          </span>
         </span>
        </div>
       </div>
       <div
        v-if="$route.name === 'Dashboard'"
        class="text-center w-full h-auto pt-4">
        <iframe
         src="https://www.youtube.com/embed/17tuys6i0RM"
         class="w-full video-height rounded-xl"
         frameborder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         allowfullscreen>
        </iframe>
       </div>
       <div v-else class="text-center w-full h-auto pt-4">
        <div v-for="video in videosTabs" :key="video.name">
         <iframe
          v-if="$route.path.includes(video.href)"
          :src="`https://www.youtube.com/embed/${video.videoUrlCode}`"
          class="w-full video-height rounded-xl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
         </iframe>
        </div>
       </div>
       <div class="sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
         @click="_cancel(false)"
         type="button"
         class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 encom_primary encom_primary_hover text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
         {{ $t("Continuer") }}
        </button>
        <button
         v-if="$route.name === 'Dashboard'"
         type="button"
         class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
         @click="_cancel(true)"
         ref="cancelButtonRef">
         {{ $t("Ne plus afficher ceci") }}
        </button>
       </div>
      </div>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import PopupModal from "../../PopupModal.vue";
import axios from "axios";
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const timezone = localStorage.getItem("hostTimeZone");

const videosTabs = [
 {
  name: "Mes Numéros",
  description: "4 minutes pour prendre en main 'Mes Numéros'",
  href: "did",
  videoUrlCode: "1fbh10Oc0QY",
 },
 {
  name: "Mes File d'attente",
  description: "3 minutes pour prendre en main 'Mes Files d'attentes'.",
  href: "queue",
  videoUrlCode: "ZlZmWu1pouY",
 },
 {
  name: "Mes Utilisateurs",
  description: "3 minutes pour prendre en main 'Mes utilisateurs'.",
  href: "extension",
  videoUrlCode: "7q0AdEMj9wM",
 },
];

export default {
 props: [
  "elementOption",
  "parseDateRange",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
  "startDate",
  "endDate",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   videosTabs,
   cron: "",
   dataset: "",
   emails: [],
   email: "",
   invalidEmail: false,
   emailRegexError: false,
   emailAlreadyExistError: false,
   message: undefined, // Main text content
   nickname: "",
   title: undefined,
   isSchedule: false,
   hour: "",
   timezone,
   subMessage: undefined, // text content before input
   instant: false,
   startAt: new Date(),
  };
 },
 components: {
  ExclamationCircleIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 methods: {
  show(opts = {}) {
   // Once we set our config, we tell the popup modal to open
   this.$refs.popup.open();
   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  _confirm() {
   try {
    this.setUpDataSet();
    this.$refs.popup.close();
    this.resolvePromise(true);
   } catch (error) {
    console.error(error);
   }
  },
  _cancel(notShowAgain) {
   if (notShowAgain) {
    localStorage.setItem("dashboard_tutorial", 0);
   }
   this.$refs.popup.close();
   this.resolvePromise(false);
   // Or you can throw an error
   // this.rejectPromise(new Error('User cancelled the dialogue'))
  },
 },
 computed: {
  ...mapGetters([
   "pbxElements",
   "pbxTimeSelected",
   "pbxTimeSelectedEnd",
   "hostName",
   "user",
  ]),
 },
 watch: {},
};
</script>

<style scoped>
.video-height {
 height: 500px;
}
</style>
