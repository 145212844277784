<template>
 <div class="w-full relative">
   <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30">
    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
   </div>
   <vue-select
    v-model="search"
    :options="data"
    :close-on-select="closeOnSelect ? true : false"
    searchable
    :loading="isLoading"
    clear-on-select
    :multiple="false"
    :placeholder="selectedCustomer ? selectedCustomer : placeholder"
    @search:input="handleSearchInput"
    :maxHeight="maxHeight"
    class="w-full bg-white border text-left border-gray-300 focus:border-gray-300 py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:ring-0 focus:outline-0 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm z-20"
   >
   </vue-select>
  </div>
</template>

<script>
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import { SearchIcon } from "@heroicons/vue/outline";

export default {
 props: [
  "placeholder",
  "selectedCustomer",
  "searchMethod",
  "data",
  "closeOnSelect",
  "isMultiple",
  "selectAllElements",
  "selectedAllElements",
  "clearAllElements",
  "elementToRemove",
  "maxHeight",
  "groupData",
  "extensionListConcat",
  "selectedElement",
 ],
 components: {
  VueSelect,
  SearchIcon,
 },
 data() {
  return {
   search: "",
   searchableTerm: "",
   isLoading: false,
  };
 },
 methods: {
  handleSearchInput(event) {
   this.searchableTerm = event.target.value;
  },
 },
 watch: {
  searchableTerm: function (val) {
   if (this.searchMethod) {
    if (val.length > 2) {
     this.searchMethod(val);
    }
   }
  },
  search: function (val) {
   this.isLoading = false;
   let number = "";
   if (val && val.length > 0) {
    number = val.split(" ")[0];
   }
   this.$emit("action", number);
  },
 },
};
</script>

<style scoped>
select {
    text-decoration: none;
}
</style>
