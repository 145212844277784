<template>
 <div
  class="text-center lg:flex lg:gap-8 lg:flex-wrap items-center sm:pb-2 space-y-4">
  <div
   v-if="
    this.$route.name !== 'ExpertStatisticsTRTC' &&
    this.$route.name !== 'ExpertStatisticsOutboundPbx' &&
    this.$route.name !== 'ExpertStatisticsOutboundTrunk' &&
    this.$route.name !== 'ExpertStatisticsCallerNumbers' &&
    this.$route.name !== 'ExpertStatisticsThirdPartyNumber' &&
    this.$route.name !== 'ExpertStatisticsQueueComparator'
   "
   class="">
   <InputSearchSelector
    :key="$route.fullPath"
    v-model:searchElement="searchElement"
    :searchData="elementListConcatReduceSliced"
    :infiniteHandler="infiniteHandler"
    :selected="selectedPbxElements"
    :urlType="urlType"
    :addElement="addPbxElement"
    :slicedListIncludes="slicedListIncludes"
    :elementListConcatReduce="elementListConcatReduce" />
   <!-- <QuickSearch
        :data="urlType === 'extension' ? extensionsList : queueList"
        :groupData="
          urlType === 'extension'
            ? elementGroupsExtensionList
            : elementGroupsQueueList
        "
        :extensionListConcat="elementListConcatReduce"
        :placeholder="
          urlType === 'extension'
            ? $t('dashboards.searchExtension')
            : $t('dashboards.searchQueue')
        "
        @all-selected="$emit('all-selected', $event)"
        @action="
          $emit('action', $event),
            (this.selections = $event),
            (selectedQueue = $event),
            (selectedElement = $event)
        "
        :isMultiple="true"
        :closeOnSelect="false"
        :selectAllElements="selectAllElements"
        :selectedAllElements="selectedAllElements"
        :clearAllElements="clearAllElements"
        :elementToRemove="elementToRemove"
        :selectedElement="selectedElement"
        :queryElement="queryElement"
      /> -->
   <div class="flex w-full gap-1 mt-0.5">
    <button
     @click="selectAll()"
     :class="[
      'p-1 rounded-md text-xs body__header--button body__header--button__text shadow-sm capitalize w-full',
     ]"
     :title="$t('selectAll')">
     {{ $t("dashboards.selectAll") }}
    </button>
    <button
     @click="
      (clearAllElements = !clearAllElements),
       this.$store.dispatch('pbxElements', null),
       this.$store.dispatch('groupSelected', false),
       this.$store.dispatch('groupSelectedName', []),
       $emit('action', []),
       (this.enabled = false),
       $emit('concatenate', false),
       $emit('clearAll', true),
       (selectedPbxElements.length = 0)
     "
     :disabled="!pbxElements || pbxElements?.length <= 0"
     :class="[
      !pbxElements || pbxElements?.length <= 0
       ? 'bg-gray-300 cursor-not-allowed text-white'
       : 'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50',
      'p-1 text-xs rounded-md shadow-sm capitalize w-full font-medium',
     ]">
     {{ $t("dashboards.clear") }}
    </button>
   </div>
  </div>
  <div
   class="flex gap-2"
   v-if="this.$route.name === 'ExpertStatisticsQueueComparator'">
   <QuickSearch
    :data="queueList"
    :placeholder="$t('header.search')"
    :isMultiple="true"
    :closeOnSelect="false"
    :hasMax="2"
    :extensionListConcat="queueList"
    @action="$emit('action', $event)" />
  </div>

  <div
   class="flex text-center items-center"
   v-if="
    this.$route.name === 'ExpertStatisticsCallerNumbers' ||
    this.$route.name === 'ExpertStatisticsThirdPartyNumber' ||
    this.$route.name === 'ExpertStatisticsTRTC'
   ">
   <quick-search-caller-numbers
    :data="didNumbers"
    :placeholder="$t('Sélectionner un numéro')"
    :closeOnSelect="true"
    @action="$emit('action', $event), (selectedElement = $event)" />
  </div>
  <!-- <SwitchGroup
   v-show="
    this.$route.name !== 'ExpertStatisticsQueueDetail' &&
    this.$route.name !== 'ExpertStatisticsCallDurations' &&
    this.$route.name !== 'ExpertStatisticsCallDurationsWaitingTime' &&
    this.$route.name !== 'ExpertStatisticsOutbound' &&
    this.$route.name !== 'ExpertStatisticsQueueComparator' &&
    this.$route.name !== 'ExpertStatisticsThirdPartyNumber' &&
    $route.name !== 'DashboardExpertStatistics' &&
    this.$route.name !== 'ExpertStatisticsDIDReport'
   "
   as="div"
   class="flex items-center mb-2">
   <Switch
    :disabled="this.elements && this.elements.length > 1 ? false : true"
    v-model="enabled"
    :class="[
     enabled ? 'body__header--button' : 'bg-gray-200',
     'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 encom_ring_color',
    ]">
    <span
     aria-hidden="true"
     :class="[
      enabled ? 'translate-x-5' : 'translate-x-0',
      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
     ]" />
   </Switch>
   <SwitchLabel as="span" class="ml-3">
    <span
     :class="
      this.elements && this.elements.length > 1
       ? `text-sm font-medium text-gray-900`
       : `text-sm text-gray-500`
     ">
     {{ $t("dashboards.concatenate") }}</span
    >
   </SwitchLabel>
  </SwitchGroup> -->
  <SwitchGroup
   v-show="
    this.$route.name !== 'ExpertStatisticsQueueDetail' &&
    this.$route.name !== 'ExpertStatisticsCallDurations' &&
    this.$route.name !== 'ExpertStatisticsCallDurationsWaitingTime' &&
    this.$route.name !== 'ExpertStatisticsOutbound' &&
    this.$route.name !== 'ExpertStatisticsQueueComparator' &&
    this.$route.name !== 'ExpertStatisticsThirdPartyNumber' &&
    $route.name !== 'DashboardExpertStatistics' &&
    $route.name !== 'DashboardExpertStatisticsTrend'
   "
   as="div"
   class="flex items-center text-center gap-2">
   <Switch
    v-show="this.elements && this.elements.length > 1"
    :disabled="this.elements && this.elements.length > 1 ? false : true"
    v-model="enabled"
    :class="[
     this.elements && this.elements.length > 1
      ? `text-sm font-medium text-gray-900 bg-teal-600`
      : `text-sm text-gray-500 bg-gray-300`,
     'relative flex gap-1 text-center h-7 w-60 lg:-mt-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
    ]">
    <span
     v-if="enabled"
     class="text-sm font-bold text-white capitalize px-2 py-0.5"
     >{{ $t("Vue détaillée") }}</span
    >
    <!-- <span class="text-sm text-gray-500">20s</span> -->
    <span
     aria-hidden="true"
     :class="[
      enabled ? 'translate-x-1 ' : 'translate-x-0 w-30',
      'pointer-events-none  h-6 text-center items-center px-2.5 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
     ]">
     <SwitchLabel as="span" class="flex cursor-pointer">
      <span v-if="enabled" class="text-sm font-bold text-gray-700 capitalize">{{
       $t("Vue consolidée")
      }}</span>
      <span v-else class="text-sm font-bold text-gray-700 capitalize">{{
       $t("Vue détaillée")
      }}</span>
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </SwitchLabel>
    </span>
    <span
     v-if="!enabled"
     class="text-sm font-bold text-white capitalize px-2 py-0.5"
     >{{ $t("Vue consolidée") }}</span
    >
    <!-- <span class="text-sm text-gray-500">20s</span> -->
   </Switch>
  </SwitchGroup>

  <div
   @click.prevent="this.$refs.tutorial.show()"
   title="Ouvrir le tutoriel"
   :class="[$route.name === 'ExpertStatisticsAnsweredUnanswered'?'top-2 right-6':'top-14 right-6','absolute  text-xs cursor-pointer']">
   <span
    class="shadow-md hover:shadow-lg inline-flex items-center rounded-full bg-blue-400 hover:bg-blue-500 px-2 py-2 hover:ring-1 hover:ring-inset hover:ring-blue-100 ring-opacity-20">
    <font-awesome-icon icon="question" class="text-blue-100 h-5 w-5" />
   </span>
  </div>
  <!-- <div
      class="flex gap-2"
      v-if="this.$route.name === 'ExpertStatisticsQueueDetail'"
    >
      <QuickSearch
        :data="queueList"
        :placeholder="$t('header.search')"
        :isMultiple="true"
        :closeOnSelect="false"
        :extensionListConcat="queueList"
        @action="
          $emit('action', $event),
            (selectedQueue = $event),
            (selectedElement = $event)
        "
      />
    </div> -->
  <!-- <div class="flex gap-2" v-if="this.$route.name === 'ExpertStatisticsTRTC'">
   <quick-search-did-numbers
    :data="didNumbers"
    :placeholder="$t('header.search')"
    :closeOnSelect="true"
    @action="$emit('action', $event), (selectedElement = $event)"
   />
  </div> -->

  <!-- <div class="inline-flex gap-0.5">
   <v-date-picker
    v-if="this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear'"
    class="inline-block h-full"
    color="teal"
    v-model="dayDate"
    :max-date="new Date()"
    mode="date">
    <template v-slot="{ togglePopover }">
     <button
      :class="[
       this.buttonSelected === $t('dashboards.day')
        ? 'body__header--button text-white'
        : 'encom_primary_text',
       'capitalize rounded items-center w-40 py-3 encom_primary_border text-sm font-medium hover:text-white encom_primary_hover focus:z-10',
      ]"
      @click="togglePopover()">
      {{ $t("dashboards.day") }}
     </button>
    </template>
   </v-date-picker>
   <div class="-ml-px relative block mb-1">
    <WeekCalendar
     :nextPage="nextPage"
     :getDate="getDate"
     :months="months"
     :parseDateRange="parseDateRange"
     @selector="
      $emit('selector', $event),
       (this.customDate = null),
       (this.dayDate = null),
       (this.selectedMonth = null)
     "
     :customDate="customDate"
     :dayDate="dayDate"
     :monthSelected="selectedMonth"
     @button-selector="$emit('button-selector', $event)"
     :buttonSelected="this.buttonSelected" />
   </div>
   <div class="-ml-px relative block mb-1">
    <button
     @click="showCalendar = !showCalendar"
     @blur="showCalendar = false"
     :class="[
      this.buttonSelected === $t('dashboards.month')
       ? 'body__header--button text-white'
       : 'encom_primary_text',
      'capitalize rounded items-center w-40 py-3 encom_primary_border text-sm font-medium hover:text-white encom_primary_hover ',
     ]">
     <span class="">{{ $t("dashboards.month") }}</span>
    </button>
    <transition
     enter-active-class="transition ease-out duration-100"
     enter-from-class="transform opacity-0 scale-95"
     enter-to-class="transform opacity-100 scale-100"
     leave-active-class="transition ease-in duration-75"
     leave-from-class="transform opacity-100 scale-100"
     leave-to-class="transform opacity-0 scale-95">
     <div
      v-show="showCalendar || isActive"
      role="menu"
      tabindex="0"
      @focus="isActive = true"
      @blur="isActive = false"
      class="z_index overflow-y-auto origin-top-right absolute left-0 mt-3 mt-2 w-52 h-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div class="flex justify-between my-2 mx-1">
       <span
        class="self-center cursor-pointer hover:bg-gray-200 rounded p-1"
        @click="previousPage()">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-5 w-5 text-gray-500"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor">
         <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7" />
        </svg>
       </span>
       <h1
        class="font-medium cursor-pointer hover:text-gray-500"
        @click="this.currentYear = this.maxYear">
        {{ currentYear }}
       </h1>
       <span
        class="self-center cursor-pointer hover:bg-gray-200 rounded p-1"
        @click="nextPage()"
        ><svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-5 w-5 text-gray-500"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor">
         <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7" /></svg
       ></span>
      </div>
      <div class="py-1 z-20 grid grid-cols-3 grid-rows-4 gap-2">
       <div as="div" v-for="(month, idx) in months" :key="month.id">
        <span
         :class="[
          !this.dayDate &&
          !this.customDate &&
          this.selectedMonth === idx &&
          this.selectedYear === this.currentYear &&
          (idx <= this.currentMonth || this.maxYear !== this.currentYear)
           ? 'body__header--button text-white'
           : idx > this.currentMonth && this.maxYear === this.currentYear
           ? 'text-gray-300 cursor-not-allowed'
           : 'hover:bg-gray-200 hover:text-gray-700 cursor-pointer',
          'px-3 py-2 rounded',
         ]"
         @click="
          idx > this.currentMonth && this.maxYear === this.currentYear
           ? ''
           : emitSelectedMonth(currentYear, month.value, idx, month.text)
         ">
         {{ month.text }}
        </span>
       </div>
      </div>
     </div>
    </transition>
   </div>

   <v-date-picker
    v-if="
     this.$route.name !== 'ExpertStatisticsCallDurations' &&
     this.$route.name !== 'ExpertStatisticsCallDurationsWaitingTime'
    "
    class="inline-block h-full"
    color="teal"
    v-model="customDate"
    :max-date="new Date()"
    mode="date"
    is-range>
    <template v-slot="{ togglePopover }">
     <button
      :class="[
       this.buttonSelected === $t('dashboards.custom')
        ? 'body__header--button text-white'
        : 'encom_primary_text',
       'capitalize rounded items-center w-40 py-3 encom_primary_border text-sm font-medium hover:text-white encom_primary_hover focus:z-10',
      ]"
      @click="togglePopover()">
      {{ $t("dashboards.custom") }}
     </button>
    </template>
   </v-date-picker>
  </div> -->

  <div
   class="relative z-0 inline-flex shadow-sm rounded-md"
   v-if="this.$route.name === 'ExpertStatisticsTRTC'">
   <button
    type="button"
    :class="[
     selectedTop === 'top10' ? 'bg-gray-50' : 'bg-white',
     'w-25 relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10',
    ]"
    @click="$emit('selectTop', 'top10')">
    Top 10
   </button>
   <button
    type="button"
    :class="[
     selectedTop === 'top50' ? 'bg-gray-50' : 'bg-white',
     '-ml-px w-25 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10',
    ]"
    @click="$emit('selectTop', 'top50')">
    Top 50
   </button>
   <button
    type="button"
    :class="[
     selectedTop === 'top500' ? 'bg-gray-50' : 'bg-white',
     '-ml-px w-25 relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10',
    ]"
    @click="$emit('selectTop', 'top500')">
    Top 500
   </button>
  </div>

  <!-- <div
   v-show="
    this.$route.name !== 'ExpertStatisticsTRTC' &&
    this.$route.name !== 'ExpertStatisticsCallDurations' &&
    this.$route.name !== 'ExpertStatisticsCallDurationsWaitingTime' &&
    this.$route.name !== 'ExpertStatisticsCallerNumbers' &&
    this.$route.name !== 'ExpertStatisticsThirdPartyNumber' &&
    this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear' &&
    this.$route.name !== 'ExpertStatisticsAnsweredDaily' &&
    this.$route.name !== 'DashboardExpertStatistics' &&
    this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekDay'
   "
   class="mx-1">
   <TimeSelector
    @timeSelector="$emit('timeSelector', $event)"
    :elements="elements"
    :clearTimeSelector="clearTimeSelector" />
  </div> -->

  <div
   class="h-10 flex rounded-md shadow-sm"
   v-show="
    this.$route.name === 'ExpertStatisticsCallDurations' ||
    this.$route.name === 'ExpertStatisticsCallDurationsWaitingTime'
   ">
   <button
    type="button"
    class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
    {{ $t("avgWaitingTime") }}
   </button>
   <Menu as="div" class="relative -ml-px block">
    <MenuButton
     class="h-10 relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
     <span class="sr-only">Open options</span>
     <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
    </MenuButton>
    <transition
     enter-active-class="transition ease-out duration-100"
     enter-from-class="transform opacity-0 scale-95"
     enter-to-class="transform opacity-100 scale-100"
     leave-active-class="transition ease-in duration-75"
     leave-from-class="transform opacity-100 scale-100"
     leave-to-class="transform opacity-0 scale-95">
     <MenuItems
      class="absolute right-0 z-20 mt-2 -mr- w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div class="py-1">
       <MenuItem
        v-for="item in durationMenuItems"
        :key="item.name"
        v-slot="{ active }">
        <button
         @click="selectedDurationMenuItem = item.name"
         :class="[
          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          'block px-4 py-2 text-sm text-medium w-full text-left',
         ]">
         {{ $t(item.name) }}
        </button>
       </MenuItem>
      </div>
     </MenuItems>
    </transition>
   </Menu>
  </div>
 </div>
 <div
  v-if="
   elements &&
   this.$route.name !== 'ExpertStatisticsQueueDetail' &&
   this.$route.name !== 'ExpertStatisticsQueueComparator' &&
   $route.name !== 'DashboardExpertStatistics' &&
   $route.name !== 'DashboardExpertStatisticsTrend'
  "
  class="pt-4">
  <div v-if="showLess" class="lg:grid grid-cols-12 gap-2">
   <span
    v-for="element in elements.slice(0, 12)"
    :key="element"
    class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
    <span class="truncate" :title="element">
     {{ element }}
    </span>
    <button
     type="button"
     @click="elementRemoved(element)"
     class="rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
   <!-- <div
    v-for="element in elements.slice(0, 12)"
    :key="element"
    class="relative rounded-lg border border-gray-300 bg-whv-if="showLess"ite px-2 py-1 shadow-sm flex items-center hover:border-gray-400">
    <div @click="elementRemoved(element)" class="flex-1 min-w-0">
     <a
      :title="`Remove - ${element}`"
      class="focus:outline-none cursor-pointer">
      <span class="absolute inset-0" aria-hidden="true" />
      <p class="text-xs font-normal text-gray-900 truncate">
       {{ element }}
      </p>
     </a>
    </div>
   </div> -->
  </div>
  <div v-else class="lg:grid grid-cols-12 gap-2">
   <span
    v-for="element in elements"
    :key="element"
    class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
    <span class="truncate" :title="element">
     {{ element }}
    </span>
    <button
     type="button"
     @click="elementRemoved(element)"
     class="rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div v-show="elements.length > 12" class="my-1">
   <span
    v-show="showLess"
    class="text-xs italic text-gray-500 flex justify-center">
    {{ $t("andNumberMoreElements", { numb: elements.length - 12 }) }}
   </span>
   <div class="flex justify-center mt-2">
    <button
     @click="showLess = !showLess"
     class="rounded-lg text-gray-700 bg-white hover:bg-grey-100 border-gray-300 hover:bg-gray-50 hover:shadow-md shadow-sm px-2 py-1 border text-xs">
     {{ showLess ? $t("showMore") : $t("showLess") }}
    </button>
   </div>
  </div>
 </div>
 <TutorialCard
  ref="tutorial"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getAlertList() : ''"
  @alertList="alertList = true"></TutorialCard>
</template>

<script>
import PopupShareReport from "./PopupShareReport.vue";
import QuickSearchCallerNumbers from "./QuickSearchCallerNumbers.vue";
import InputSearchSelector from "../InputSearchSelector.vue";
import TutorialCard from "../PBX/Dashboard/TutorialCard.vue";
import axios from "axios";

import { ref } from "vue";
import { mapGetters } from "vuex";

import TimeSelector from "./TimeSelector.vue";
import WeekCalendar from "../WeekCalendar.vue";

import QuickSearch from "../QuickSearch.vue";
import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Popover,
 PopoverButton,
 PopoverPanel,
 Switch,
 SwitchGroup,
 SwitchLabel,
} from "@headlessui/vue";
import QuickSearchDidNumbers from "../QuickSearchDidNumbers.vue";
import { SearchIcon, ChevronDownIcon } from "@heroicons/vue/outline";
import Slider from "@vueform/slider";

const account = localStorage.getItem("account");
const isLocal = localStorage.getItem("isLocal");

import InfiniteLoading from "vue-infinite-loading";

const durationMenuItems = [
 { name: "percentageWaitingTime", href: "#" },
 { name: "durationWaitingTime", href: "#" },
];

export default {
 props: [
  "buttonSelected",
  "concatenateData",
  "elements",
  "urlType",
  "elementsLength",
  "didNumbers",
  "selectedTop",
  "queryElement",
  "comparatorData",
  "groupDatesQueueReport",
 ],
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
  QuickSearch,
  Switch,
  SwitchGroup,
  SwitchLabel,
  TimeSelector,
  WeekCalendar,
  QuickSearchDidNumbers,
  SearchIcon,
  InfiniteLoading,
  InputSearchSelector,
  Slider,
  ChevronDownIcon,
  QuickSearchCallerNumbers,
  PopupShareReport,
  TutorialCard,
 },
 setup() {
  const enabled = ref(false);

  return {
   enabled,
  };
 },
 data() {
  return {
   selectedDurationMenuItem: "avgWaitingTime",
   durationMenuItems,
   searchElement: "",
   showElementList: false,
   showClickableList: false,
   selectAllElements: false,
   selectedElement: "",
   pbxMapData: {},
   selectedQueue: undefined,
   isLocal,
   selections: [],
   account,
   currentYear: "",
   currentMonth: "",
   selectedYear: "",
   date: null,
   dayDate: null,
   customDate: null,
   day: "",
   elementToRemove: "",
   hour: "",
   isActive: false,
   maxYear: "",
   minYear: "2000",
   month: "",
   selector: "hour",
   selectedMonth: null,
   search: true,
   searchActive: false,
   showCalendar: false,
   showLess: true,
   months: [
    { id: 1, text: "Jan", value: "01" },
    { id: 2, text: "Feb", value: "02" },
    { id: 3, text: "Mar", value: "03" },
    { id: 4, text: "Apr", value: "04" },
    { id: 5, text: "May", value: "05" },
    { id: 6, text: "Jun", value: "06" },
    { id: 7, text: "Jul", value: "07" },
    { id: 8, text: "Aug", value: "08" },
    { id: 9, text: "Sep", value: "09" },
    { id: 10, text: "Oct", value: "10" },
    { id: 11, text: "Nov", value: "11" },
    { id: 12, text: "Dec", value: "12" },
   ],
   extensionsList: [],
   DIDsList: [],
   queueList: [],
   elementListConcatReduce: [],
   elementGroupsExtensionList: [],
   elementGroupsDIDList: [],
   elementGroupsQueueList: [],
   slicedExtensionList: [],
   sliceElements: 500,
   elementListConcatReduceSliced: [],
   selectedPbxElements: [],
   waitingTimeRange: 30,
  };
 },
 methods: {
  selectAll() {
   this.enabled = true;
   if (this.urlType === "extension") {
    this.selectedPbxElements = this.extensionsList.map(({ value }) => value);
   } else {
    if (this.urlType === "queue") {
     this.selectedPbxElements = this.queueList.map(({ value }) => value);
    } else {
     this.selectedPbxElements = this.DIDsList.map(({ value }) =>
      value.split(" ")[0].replace(/[^0-9]/g, "")
     );
    }
   }
   this.$store.dispatch("pbxElements", this.selectedPbxElements);
   this.$emit("action", this.selectedPbxElements);
  },
  infiniteHandler($state) {
   setTimeout(
    function () {
     if (
      this.elementListConcatReduceSliced.length ==
      this.elementListConcatReduce.length
     ) {
      $state.complete();
     } else {
      this.elementListConcatReduceSliced =
       this.elementListConcatReduceSliced.concat(
        this.elementListConcatReduce.slice(
         this.sliceElements - 500,
         this.sliceElements
        )
       );
      this.sliceElements = this.sliceElements + 500;
      // this.concatArray();
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  addPbxElement(element) {
   let elementValue = null;
   if (typeof element.value !== "object") {
    elementValue = element?.value?.split(" ")[0].replace(/[^0-9]/g, "");
   }
   let groupsSelected = this.groupSelectedName;
   if (
    element &&
    element.value &&
    element.value.length > 0 &&
    typeof element.value === "object"
   ) {
    this.enabled = true;
    this.$store.dispatch("groupSelected", true);
    let arrayLength = element.value.length;
    if (groupsSelected.indexOf(element.label) < 0) {
     groupsSelected.push(element.label);
    } else {
     groupsSelected.splice(groupsSelected.indexOf(element.label), 1);
    }
    this.$store.dispatch("groupSelectedName", groupsSelected);
    for (let index = 0; index < arrayLength; index++) {
     let elementIdx = element.value[index];
     elementIdx = elementIdx.split(" ")[0].replace(/[^0-9]/g, "");
     if (this.selectedPbxElements.indexOf(elementIdx) < 0) {
      this.selectedPbxElements.push(elementIdx);
     } else {
      this.selectedPbxElements.splice(
       this.selectedPbxElements.indexOf(elementIdx),
       1
      );
     }
    }
   } else {
    this.$store.dispatch("groupSelected", false);
    if (elementValue) {
     if (this.selectedPbxElements.length > 0) {
      if (this.selectedPbxElements.indexOf(elementValue) >= 0) {
       this.selectedPbxElements.splice(
        this.selectedPbxElements.indexOf(elementValue),
        1
       );
      } else {
       this.selectedPbxElements.push(elementValue);
      }
     } else {
      this.selectedPbxElements.push(elementValue);
     }
    }
   }
   this.$store.dispatch("pbxElements", this.selectedPbxElements);
   this.$emit("action", this.selectedPbxElements);
  },
  slicedListIncludes() {
   let flag = false;
   for (
    let index = 0;
    index < this.elementListConcatReduceSliced.length;
    index++
   ) {
    const element = this.elementListConcatReduceSliced[index];
    if (element.label.includes(this.searchElement)) {
     flag = true;
    }
   }
   return flag;
  },
  elementRemoved(element) {
   let splitedElement = element.includes("-") ? element.split("-") : element;
   let elementValue = element.includes("-") ? splitedElement[0] : element;
   elementValue = elementValue.replace(/[^0-9]/g, "");
   console.log("elementValue", elementValue);
   if (this.selectedPbxElements.indexOf(elementValue) >= 0) {
    this.selectedPbxElements.splice(
     this.selectedPbxElements.indexOf(elementValue),
     1
    );
   }
   this.$store.dispatch("pbxElements", this.selectedPbxElements);
   this.$emit("action", this.selectedPbxElements);
  },

  goToDetail(event) {
   this.$router.push(`/expert-statistics/${event.type}/${event.value}`);
  },
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  getYear() {
   let year = new Date().getFullYear();
   let month = new Date().getMonth();
   this.maxYear = year;
   this.currentYear = year;
   this.currentMonth = month;
  },
  emitSelectedMonth(year, month, idx, text) {
   this.dayDate = null;
   this.customDate = null;
   this.selectedMonth = idx;
   this.selectedYear = year;
   let buttonSelector = this.$t("dashboards.month");
   let emittedDate = this.currentYear + month;
   this.$emit("selector", emittedDate);
   this.$emit("button-selector", buttonSelector, text);
   this.$store.dispatch("buttonSelector", buttonSelector);
   setTimeout(() => {
    this.isActive = false;
   }, 600);
  },
  nextPage() {
   if (this.currentYear < this.maxYear) {
    this.currentYear = this.currentYear + 1;
   } else {
    this.currentYear = this.currentYear;
   }
  },
  previousPage() {
   if (this.currentYear > this.minYear) {
    this.currentYear = this.currentYear - 1;
   } else {
    this.currentYear = this.currentYear;
   }
  },
  siphenPeriodMonth(period) {
   let month = period.slice(-2);
   let year = period.slice(0, 4);
   return month + " - " + year;
  },
  parseDate(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   let hours = ("0" + newDate.getHours()).slice(-2);
   let minutes = ("0" + newDate.getMinutes()).slice(-2);
   let seconds = ("0" + newDate.getSeconds()).slice(-2);
   this.month = year + month;
   this.day = year + month + day;
   this.hour = hours + minutes;
   let fullDate =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
   return String(fullDate);
  },
  parseDateRange(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   let fullDay = "";
   fullDay = year + month + day;

   return String(fullDay);
  },
  async concatArray() {
   let extensionListConcat = [];
   let queueListConcat = [];
   let DIDListConcat = [];
   let objDID = {};
   let objExtensions = {};
   let objQueues = {};
   let objDataGroups = {};
   objDataGroups["methods"] = [];
   let objDataElements = {};
   objDataElements["methods"] = [];
   let extensionsArrayGroup = [];
   let DIDArrayGroup = [];
   let queuesArrayGroup = [];
   let extensionsOnGroup = [];
   let queuesOnGroup = [];

   const objectDataGroups = await this.getElementGroups();
   const objectData = await this.getPbxMap();

   if (objectDataGroups?.length || objectData) {
    let objElementGroupsExtensionFiltered = {};
    let objElementGroupsDIDFiltered = {};
    let objElementGroupsQueueFiltered = {};

    let keysExtensions = Object.keys(objectData.extensions);
    let keysDIDs = Object.values(objectData.dids);
    let keysQueues = Object.keys(objectData.call_queues);

    let elementGroupsExtensionFiltered = objectDataGroups.filter(
     res => res.type == 0
    );
    let elementGroupsQueueFiltered = objectDataGroups.filter(
     res => res.type == 4
    );
    let elementGroupsDIDFiltered = objectDataGroups.filter(
     res => res.type == 1
    );
    let elementGroups = [];
    let elementGroupsQueues = [];
    let elementGroupsDIDs = [];

    elementGroupsExtensionFiltered.forEach(res => {
     let arrResources = [];
     let resources = JSON.parse(res.resources);
     resources.forEach(res =>
      arrResources.push(`${res}:${objectData.extensions[res]}`)
     );
     objElementGroupsExtensionFiltered[res.name] = arrResources;
    });

    elementGroupsQueueFiltered.forEach(res => {
     let arrResources = [];
     let resources = JSON.parse(res.resources);
     resources.forEach(res =>
      objectData.call_queues[res] && objectData.call_queues[res].name
       ? arrResources.push(`${res}:${objectData.call_queues[res]?.name}`)
       : ""
     );
     objElementGroupsQueueFiltered[res.name] = arrResources;
    });

    elementGroupsDIDFiltered.forEach(res => {
     let arrResources = [];
     let resources = JSON.parse(res.resources);
     resources.forEach(r => arrResources.push(`${r}`));
     objElementGroupsDIDFiltered[res.name] = arrResources;
    });

    elementGroups = Object.keys(objElementGroupsExtensionFiltered);
    elementGroupsQueues = Object.keys(objElementGroupsQueueFiltered);
    elementGroupsDIDs = Object.keys(objElementGroupsDIDFiltered);

    elementGroups.forEach((element, indexEl) => {
     extensionsArrayGroup = [];
     objDataGroups = {};
     objExtensions = {};
     objExtensions["value"] = null;
     objExtensions["label"] = null;
     objExtensions["type"] = "extension";
     this.elementGroupsExtensionList.push(objExtensions);

     for (
      let index = 0;
      index < objElementGroupsExtensionFiltered[element].length;
      index++
     ) {
      objExtensions = {};
      objExtensions["value"] = null;
      objExtensions["label"] = null;
      const elementExt = objElementGroupsExtensionFiltered[element][index];
      if (elementExt) {
       let splitElementExt = elementExt.split(":");
       extensionsOnGroup.push(splitElementExt[0]);
       objExtensions["value"] = splitElementExt[0];
       objExtensions["label"] = splitElementExt[0];
       objExtensions["group"] = true;
       extensionsArrayGroup.push(objExtensions);
      }
     }
     objDataGroups["name"] = element;
     objDataGroups["methods"] = extensionsArrayGroup;
     this.$route.name === "ExpertStatisticsQueueDetail"
      ? ""
      : extensionListConcat.splice(indexEl, 1, objDataGroups);
    });
    elementGroupsQueues.forEach((element, indexEl) => {
     queuesArrayGroup = [];
     objDataGroups = {};
     objQueues = {};
     objQueues["value"] = null;
     objQueues["label"] = null;
     objQueues["type"] = "queue";
     this.elementGroupsQueueList.push(objQueues);

     for (
      let index = 0;
      index < objElementGroupsQueueFiltered[element].length;
      index++
     ) {
      objQueues = {};
      objQueues["value"] = null;
      objQueues["label"] = null;
      const elementExt = objElementGroupsQueueFiltered[element][index];

      let splitElementExt = elementExt ? elementExt.split(":") : undefined;

      if (splitElementExt && splitElementExt.length > 0) {
       queuesOnGroup.push(splitElementExt[0]);
       objQueues["value"] = splitElementExt[0];
       objQueues["label"] = splitElementExt[0];
       objQueues["group"] = true;
       queuesArrayGroup.push(objQueues);
      }
     }
     objDataGroups["name"] = element;
     objDataGroups["methods"] = queuesArrayGroup;
     this.$route.name === "ExpertStatisticsQueueDetail"
      ? ""
      : queueListConcat.splice(indexEl, 1, objDataGroups);
    });
    elementGroupsDIDs.forEach((element, indexEl) => {
     DIDArrayGroup = [];
     objDataGroups = {};
     let objDID = {};
     objDID["value"] = null;
     objDID["label"] = null;
     objDID["type"] = "did";
     this.elementGroupsDIDList.push(objDID);

     for (
      let index = 0;
      index < objElementGroupsDIDFiltered[element].length;
      index++
     ) {
      objDID = {};
      objDID["value"] = null;
      objDID["label"] = null;
      const el = objElementGroupsDIDFiltered[element][index];
      if (el) {
       //  let splitElementExt = el.split(":");
       extensionsOnGroup.push(el);
       objDID["value"] = el;
       objDID["label"] = el;
       objDID["group"] = true;
       DIDArrayGroup.push(objDID);
      }
     }
     objDataGroups["name"] = element;
     objDataGroups["methods"] = DIDArrayGroup;
     this.$route.name === "ExpertStatisticsQueueDetail"
      ? ""
      : DIDListConcat.splice(indexEl, 1, objDataGroups);
    });
    let obj = {};
    let objDID = {};
    let objQueue = {};
    let extensionsArray = [];
    let DIDsArray = [];
    let queuesArray = [];

    // extensionsOnGroup.forEach((element, index) => {
    //   const indexExt = keysExtensions.indexOf(String(element));
    //   if (indexExt > -1) {
    //     keysExtensions.splice(indexExt, 1);
    //   }
    // });

    // queuesOnGroup.forEach((element, index) => {
    //   const indexExt = keysQueues.indexOf(String(element));
    //   if (indexExt > -1) {
    //     keysQueues.splice(indexExt, 1);
    //   }
    // });
    keysDIDs.forEach((element, index) => {
     obj = {};
     obj["value"] = element;
     //  let elementName = undefined;
     //  if (objectData.did[element] === " ") {
     //   elementName = "Empty";
     //  } else {
     //   elementName = objectData.extensions[element];
     //  }
     obj["label"] = element;
     obj["type"] = "did";
     obj["group"] = false;
     this.DIDsList.push(obj);
     DIDsArray.push(obj);
    });
    keysExtensions.forEach((element, index) => {
     obj = {};
     obj["value"] = keysExtensions[index];
     let elementName = undefined;
     if (objectData.extensions[element] === " ") {
      elementName = "Empty";
     } else {
      elementName = objectData.extensions[element];
     }
     obj["label"] = keysExtensions[index] + " - " + elementName;
     obj["type"] = "extension";
     obj["group"] = false;
     this.extensionsList.push(obj);
     extensionsArray.push(obj);
    });
    keysQueues.forEach((element, index) => {
     objQueue = {};
     objQueue["value"] = keysQueues[index];
     objQueue["label"] =
      keysQueues[index] + " - " + objectData.call_queues[element].name;
     objQueue["type"] = "queue";
     objQueue["group"] = false;
     this.queueList.push(objQueue);
     queuesArray.push(objQueue);
    });

    if (this.urlType === "extension") {
     objDataElements["name"] = "Elements";
     objDataElements["methods"] = extensionsArray;

     extensionListConcat.push(objDataElements);
     this.elementListConcatReduce = extensionListConcat.reduce(
      (flat, constructor) => {
       return flat
        .concat({
         label: constructor.name,
         value: constructor.methods.map(method => method.value),
         isConstructor: true,
        })
        .concat(
         constructor.methods.map(method => ({
          label: method.label,
          value: method.value,
          group: method.group,
         }))
        );
      },
      []
     );
    } else {
     if (this.urlType === "queue") {
      objDataElements["name"] = "Elements";
      objDataElements["methods"] = queuesArray;

      queueListConcat.push(objDataElements);

      this.elementListConcatReduce = queueListConcat.reduce(
       (flat, constructor) => {
        return flat
         .concat({
          label: constructor.name,
          value: constructor.methods.map(method => method.value),
          isConstructor: true,
         })
         .concat(
          constructor.methods.map(method => ({
           label: method.label,
           value: method.value,
           group: method.group,
          }))
         );
       },
       []
      );
     } else {
      objDataElements["name"] = "Elements";
      objDataElements["methods"] = DIDsArray;

      DIDListConcat.push(objDataElements);
      this.elementListConcatReduce = DIDListConcat.reduce(
       (flat, constructor) => {
        return flat
         .concat({
          label: constructor.name,
          value: constructor.methods.map(method => method.value),
          isConstructor: true,
         })
         .concat(
          constructor.methods.map(method => ({
           label: method.label,
           value: method.value,
           group: method.group,
          }))
         );
       },
       []
      );
     }
    }
   }

   this.elementListConcatReduce = this.elementListConcatReduce.filter(el => {
    return el.label !== "Elements" && (!el.group || el.isConstructor);
   });
  },
  async getElementGroups() {
   let objectData = undefined;
   if (this.hostName && this.hostName !== "null") {
    try {
     const res = await axios.get(
      `${localStorage.getItem("apiUrl")}/api/v1.2/${
       this.hostName
      }/resource-groups`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
       },
      }
     );
     objectData = res.data;

     return objectData;
     // this.elementsDataExtensions = objectData.extensions;
     // this.elementsDataQueues = objectData.queues;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getPbxMap() {
   if (this.hostName && this.hostName !== "null") {
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/map`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     });
     this.$store.dispatch("pbxMap", res.data);
     localStorage.setItem("pbxMap", JSON.stringify(res.data));
     this.pbxMapData = res.data;

     if (
      !res.data ||
      (res.data && Object.keys(res.data).length == 0) ||
      (res.data && !res.data.active)
     ) {
      this.$router.push("/page-unavailable");
     }

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  sendPbxConcatenation(val) {
   setTimeout(() => {
    this.$emit("concatenate", val, this.elements);
   }, 200);
  },
 },
 mounted() {
  this.getYear();
  this.concatArray();
  if (this.pbxElements && this.pbxElements.length > 0) {
   this.selectedPbxElements = this.pbxElements;
   if (this.groupSelected) {
    this.enabled = true;
   }
   if (this.pbxElements.length >= 20) {
    this.enabled = true;
   }
  } else {
   this.selectedPbxElements = [];
  }

  // if (this.pbxElements && this.pbxElements.length > 2) {
  //   this.enabled = true;
  // }
 },
 watch: {
  groupDatesQueueReport: function (val) {
   if (!val) this.enabled = false;
  },
  selectedDurationMenuItem: function (val) {
   this.$emit("selectedDurationMenuItem", val);
  },
  waitingTimeRange: function (val) {
   this.$emit("waitingTimeRange", val);
  },
  urlType: function (val) {
   this.elementListConcatReduceSliced.length = 0;
   this.selectedPbxElements.length = 0;
   this.$store.dispatch("pbxElements", this.selectedPbxElements);
   this.$store.dispatch("groupSelected", false);
   this.$store.dispatch("groupSelectedName", []);
   this.$emit("concatenate", false);
   this.$emit("action", this.selectedPbxElements);
   this.sliceElements = 500;
   //  this.concatArray();
   if (!this.pbxElements || this.pbxElements.length == 0) {
    this.enabled = false;
   }
  },
  hostName: function (val) {
   this.elementListConcatReduceSliced.length = 0;
   this.sliceElements = 500;
   this.concatArray();
  },
  selectedQueue: function (val) {
   let name = [];
   if (this.pbxMapData && this.urlType === "queue") {
    for (let index = 0; index < val.length; index++) {
     const element = val[index];
     let newName = element + " - " + this.pbxMapData.call_queues[element].name;
     name.push(newName);
    }
    this.$emit("queueName", name);
   }
  },
  date: function (val) {
   let dayRange = "";
   let buttonSelector = "";
   if (typeof val === "object" && val !== null) {
    if (Object.keys(val).length > 1) {
     buttonSelector = "custom";
     dayRange =
      this.parseDateRange(val.start) + "-" + this.parseDateRange(val.end);
     this.$emit("selector", dayRange);
     this.$emit("button-selector", buttonSelector);
     this.$store.dispatch("buttonSelector", buttonSelector);
    } else {
     buttonSelector = this.$t("dashboards.day");
     this.parseDate(val);
     this.$emit("selector", this.day);
     this.$emit("button-selector", buttonSelector);
     this.$store.dispatch("buttonSelector", buttonSelector);
    }
   } else {
    this.parseDate(val);
    this.$emit("selector", this.day);
   }
  },
  dayDate: function (val) {
   if (val) {
    this.customDate = null;
    let dayRange = "";
    let buttonSelector = "";
    if (typeof val === "object" && val !== null) {
     if (Object.keys(val).length > 1) {
      buttonSelector = "custom";
      dayRange =
       this.parseDateRange(val.start) + "-" + this.parseDateRange(val.end);
      this.$emit("selector", dayRange);
      this.$emit("button-selector", buttonSelector);
      this.$store.dispatch("buttonSelector", buttonSelector);
     } else {
      buttonSelector = this.$t("dashboards.day");
      this.parseDate(val);
      this.$emit("selector", this.day);
      this.$emit("button-selector", buttonSelector);
      this.$store.dispatch("buttonSelector", buttonSelector);
     }
    } else {
     this.parseDate(val);
     this.$emit("selector", this.day);
    }
   }
  },
  customDate: function (val) {
   if (val) {
    this.dayDate = "";
    let dayRange = "";
    let buttonSelector = "";
    if (typeof val === "object" && val !== null) {
     if (Object.keys(val).length > 1) {
      buttonSelector = "custom";
      dayRange =
       this.parseDateRange(val.start) + "-" + this.parseDateRange(val.end);
      this.$emit("selector", dayRange);
      this.$emit("button-selector", buttonSelector);
      this.$store.dispatch("buttonSelector", buttonSelector);
     } else {
      buttonSelector = this.$t("dashboards.day");
      this.parseDate(val);
      this.$emit("selector", this.day);
      this.$emit("button-selector", buttonSelector);
      this.$store.dispatch("buttonSelector", buttonSelector);
     }
    } else {
     this.parseDate(val);
     this.$emit("selector", this.day);
    }
   }
  },
  enabled: function (val) {
   if (!this.pbxElements || this.pbxElements.length == 0) {
    this.$emit("loading", false);
   } else {
    this.$emit("loading", true);
    this.sendPbxConcatenation(val);
   }
  },
  pbxElements: function (val) {
   let arr = [];
   if (val) {
    arr = val;
   }
   if (arr.length < 2) {
    this.enabled = false;
   }
  },
  concatenateData: function (val) {
   this.enabled = val;
  },
  selections: function (val) {
   if (val.length < 2) {
    this.enabled = false;
   }
  },
  selectHostname: function (val) {
   this.concatArray();
  },
 },
 computed: {
  ...mapGetters([
   "buttonSelector",
   "pbxElements",
   "hostName",
   "groupSelected",
   "groupSelectedName",
  ]),
  monthsForCurrentYear() {
   let months = [];
   let yearSlice = String(new Date().getFullYear());
   let month = new Date().getMonth() + 1;

   for (let index = 1; index <= month; index++) {
    months.push(`${yearSlice}` + `${("0" + String(index)).slice(-2)}`);
   }
   return months;
  },
 },
};
</script>

<style>
.z_index {
 z-index: 20;
}

.call_queue_table_heading {
 padding: 10px 40px 10px 40px;
}
</style>
