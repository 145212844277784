<template>
  <button
    :class="[
      this.buttonSelected === $t('dashboards.week')
        ? 'encom_primary text-white'
        : 'encom_primary_text',
      'rounded capitalize items-center w-40 py-3 encom_primary_border text-sm font-medium hover:text-white encom_primary_hover ',
    ]"
    @click="openWeekCalendar = !openWeekCalendar"
    @blur="openWeekCalendar = false"
  >
    <span class="">{{ $t("dashboards.week") }}</span>
  </button>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      v-show="openWeekCalendar || isActive"
      role="menu"
      tabindex="0"
      @focus="isActive = true"
      @blur="isActive = false"
      class="z_index overflow-y-auto origin-top-right absolute left-0 mt-3 mt-2 w-60 h-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <div class="flex justify-between my-2 mx-1">
        <span
          class="self-center cursor-pointer hover:bg-gray-200 rounded p-1"
          @click="previousPage()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </span>
        <div class="flex justify-between">
          <h1 class="font-medium cursor-pointer hover:text-gray-500 mr-2">
            {{ getMonthName(calendarMonth) }}
          </h1>
          <h1 class="font-medium cursor-pointer hover:text-gray-500">
            {{ calendarYear }}
          </h1>
        </div>

        <span
          class="self-center cursor-pointer hover:bg-gray-200 rounded p-1"
          @click="nextPage()"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            /></svg
        ></span>
      </div>
      <div class="">
        <div
          :class="`grid grid-cols-7 grid-rows-7 min-w-full overflow-y-hidden`"
        >
          <div
            class="row-start-1 col-span-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            v-for="thead in calendarThead"
            :key="thead.id"
          >
            {{ thead.text }}
          </div>
          <div
            class="row-span-1 col-span-7 overflow-y-hidden"
            v-for="(weekDays, idx) in calendarDays"
            :key="weekDays"
          >
            <div v-for="days in weekDays" :key="days">
              <ul
                :class="[
                  selectedDaysIdx === idx &&
                  selectedMonth === calendarMonth &&
                  selectedYear === calendarYear &&
                  !customDate &&
                  !dayDate &&
                  monthSelected == null
                    ? 'encom_primary text-white'
                    : !allowWeekSelection(days)
                    ? 'cursor-not-allowed'
                    : 'hover:bg-gray-100',
                  `rounded-full cursor-pointer`,
                ]"
                @click="allowWeekSelection(days) ? emitWeekDays(days, idx) : ''"
              >
                <li class="grid grid-cols-7">
                  <p v-for="day in days" :key="day" :class="['col-span-1']">
                    <span
                      v-if="day"
                      :class="[
                        (day.getDate() > currentDay &&
                          currentMonth === calendarMonth &&
                          currentYear === calendarYear) ||
                        day.getMonth() !== calendarMonth
                          ? 'text-gray-400'
                          : '',
                      ]"
                    >
                      {{ day.getDate() }}</span
                    >
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const currentDay = new Date().getDate();
const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
const calendarMonth = new Date().getMonth();
const calendarYear = new Date().getFullYear();

export default {
  props: [
    "buttonSelected",
    "getDate",
    "parseDateRange",
    "customDate",
    "dayDate",
    "monthSelected",
  ],
  data() {
    return {
      selectedDaysIdx: undefined,
      selectedColumn: null,
      openWeekCalendar: false,
      isActive: false,
      calendarThead: [
        { id: 0, text: "m", value: "Mon" },
        { id: 1, text: "t", value: "Tue" },
        { id: 2, text: "w", value: "Wed" },
        { id: 3, text: "t", value: "Thu" },
        { id: 4, text: "f", value: "Fri" },
        { id: 5, text: "s", value: "Sat" },
        { id: 6, text: "s", value: "Sun" },
      ],
      calendarDays: [
        { days: [] },
        { days: [] },
        { days: [] },
        { days: [] },
        { days: [] },
        { days: [] },
      ],
      months: [
        { id: 0, text: "Jan", value: "01" },
        { id: 1, text: "Feb", value: "02" },
        { id: 2, text: "Mar", value: "03" },
        { id: 3, text: "Apr", value: "04" },
        { id: 4, text: "May", value: "05" },
        { id: 5, text: "Jun", value: "06" },
        { id: 6, text: "Jul", value: "07" },
        { id: 7, text: "Aug", value: "08" },
        { id: 8, text: "Sep", value: "09" },
        { id: 9, text: "Oct", value: "10" },
        { id: 10, text: "Nov", value: "11" },
        { id: 11, text: "Dec", value: "12" },
      ],
      selectorButton: "",
      calendarMonth,
      calendarYear,
      selectedMonth: "",
      selectedYear: "",
      selectedDays: [],
      currentDay,
      currentMonth,
      currentYear,
    };
  },
  methods: {
    allowWeekSelection(days) {
      let flag = true;
      let countOfDays = 0;
      if (
        this.currentMonth === this.calendarMonth &&
        this.currentYear <= this.calendarYear
      ) {
        for (let index = 0; index < days.length; index++) {
          const element = days[index];
          if (element && element.getDate() < this.currentDay) {
            countOfDays = countOfDays + 1;
          }
          if (element && element.getMonth() > this.currentMonth) {
            countOfDays = 0;
          }
        }
        if (countOfDays <= 0) {
          flag = false;
        }
      }
      return flag;
    },
    emitWeekDays(days, idx) {
      this.selectedDaysIdx = idx;

      let idxNotNull = 0;
      for (let index = 0; index < days.length; index++) {
        const element = days[index];
        if (element) {
          idxNotNull = index;
        }
      }
      // let today = new Date();
      let getMissingDays = "end";
      let count = 0;
      let weekRange = null;
      let buttonSelector = "";
      let firstAndLastDay = [];
      let firstAndLastDaySorted = [];

      this.selectedMonth = new Date(days[idxNotNull]).getMonth();
      this.selectedYear = new Date(days[idxNotNull]).getFullYear();

      for (let index = 0; index < days.length; index++) {
        const firstDate = days[index];
        if (!firstDate) {
          if (index == 0) {
            getMissingDays = "start";
          }
          count = count + 1;
        } else {
          firstAndLastDay.push(firstDate);
        }
      }
      if (count > 0) {
        if (getMissingDays === "start") {
          for (let index = count - 1; index >= 0; index--) {
            firstAndLastDay.unshift(
              new Date(this.calendarYear, this.calendarMonth, -index)
            );
          }
        } else {
          for (let index = 1; index <= count; index++) {
            firstAndLastDay.unshift(
              new Date(this.calendarYear, this.calendarMonth + 1, index)
            );
          }
        }
      }
      firstAndLastDaySorted = firstAndLastDay.sort((a, b) => {
        return a - b;
      });
      weekRange =
        this.parseDateRange(firstAndLastDaySorted[0]) +
        "-" +
        this.parseDateRange(
          firstAndLastDaySorted[firstAndLastDaySorted.length - 1]
        );
      this.$emit("selector", weekRange);
      buttonSelector = "week";
      this.$emit("button-selector", buttonSelector);
    },
    // checkSelectedDays(days) {
    //   if (this.selectedDays.length > 0) {
    //     for (var i = 0; i < this.selectedDays.length; ++i) {
    //       console.log(days[i], this.selectedDays[i]);
    //       if (days[i] !== this.selectedDays[i]) return false;
    //     }
    //     return true;
    //   }
    // },
    previousPage() {
      if (this.calendarMonth > 0) {
        this.calendarMonth = this.calendarMonth - 1;
      } else {
        this.calendarMonth = 11;
        this.calendarYear = this.calendarYear - 1;
      }
    },
    nextPage() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      if (
        this.calendarYear == currentYear &&
        this.calendarMonth == currentMonth
      ) {
        this.calendarMonth = this.calendarMonth;
        this.calendarYear = this.calendarYear;
      } else {
        if (this.calendarMonth < 11) {
          this.calendarMonth = this.calendarMonth + 1;
        } else {
          this.calendarMonth = 0;
          this.calendarYear = this.calendarYear + 1;
        }
      }
    },
    getMonthName(id) {
      return this.months[id].text;
    },
    getDaysOfMonths(month, year) {
      let date = new Date(year, month, 1);
      let days = [];
      this.calendarDays[0] = {};
      this.calendarDays[0] = { days: [] };
      this.calendarDays[1] = { days: [] };
      this.calendarDays[2] = { days: [] };
      this.calendarDays[3] = { days: [] };
      this.calendarDays[4] = { days: [] };
      this.calendarDays[5] = { days: [] };
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      let firstDay = days[0].getDay() - 1;
      if (firstDay < 0) {
        firstDay = 0;
      }
      if (firstDay === 0 && days[0].toString().includes("Sun")) {
        for (let index = 0; index < 6; index++) {
          this.calendarDays[0].days.push(null);
        }
        while (this.calendarDays[0].days.length < 7) {
          this.calendarDays[0].days.push(
            days[this.calendarDays[0].days.length - 6]
          );
        }
        while (this.calendarDays[1].days.length < 7) {
          this.calendarDays[1].days.push(
            days[this.calendarDays[1].days.length + 1]
          );
        }
        while (this.calendarDays[2].days.length < 7) {
          this.calendarDays[2].days.push(
            days[this.calendarDays[2].days.length + 8]
          );
        }
        while (this.calendarDays[3].days.length < 7) {
          this.calendarDays[3].days.push(
            days[this.calendarDays[3].days.length + 15]
          );
        }
        while (this.calendarDays[4].days.length < 7) {
          this.calendarDays[4].days.push(
            days[this.calendarDays[4].days.length + 22]
          );
        }
        while (this.calendarDays[5].days.length < 7) {
          this.calendarDays[5].days.push(
            days[this.calendarDays[5].days.length + 29]
          );
        }
      } else {
        for (let index = 0; index < firstDay; index++) {
          this.calendarDays[0].days.push(null);
        }

        while (this.calendarDays[0].days.length < 7) {
          this.calendarDays[0].days.push(
            days[this.calendarDays[0].days.length - firstDay]
          );
        }
        while (this.calendarDays[1].days.length < 7) {
          this.calendarDays[1].days.push(
            days[this.calendarDays[1].days.length + (7 - firstDay)]
          );
        }
        while (this.calendarDays[2].days.length < 7) {
          this.calendarDays[2].days.push(
            days[this.calendarDays[2].days.length + (14 - firstDay)]
          );
        }
        while (this.calendarDays[3].days.length < 7) {
          this.calendarDays[3].days.push(
            days[this.calendarDays[3].days.length + (21 - firstDay)]
          );
        }
        while (this.calendarDays[4].days.length < 7) {
          this.calendarDays[4].days.push(
            days[this.calendarDays[4].days.length + (28 - firstDay)]
          );
        }
        while (this.calendarDays[5].days.length < 7) {
          this.calendarDays[5].days.push(
            days[this.calendarDays[5].days.length + (35 - firstDay)]
          );
        }
      }
      let emptyDatesEnd = this.findEmptyRowFromCalendar();
      this.addEmptyDates(
        this.calendarDays[0],
        this.calendarDays[emptyDatesEnd]
      );
    },
    addEmptyDates(daysStart, daysEnd) {
      let emptyIdxStart = [];
      let emptyIdxEnd = [];
      for (let index = 0; index < daysStart.days.length; index++) {
        const element = daysStart.days[index];
        if (!element) {
          emptyIdxStart.push(index);
        }
      }
      if (daysEnd) {
        for (let index = 0; index < daysEnd.days.length; index++) {
          const element = daysEnd.days[index];
          if (!element) {
            emptyIdxEnd.push(index);
          }
        }
      }
      this.getMissingDays(emptyIdxStart, emptyIdxEnd);
    },
    getMissingDays(arrDaysStart, arrDaysEnd) {
      let targetPreviousMonth = undefined;
      // if (this.calendarMonth == 0) {
      //   targetPreviousMonth = 11;
      // } else {
      //   targetPreviousMonth = this.calendarMonth - 1;
      // }
      let targetNextMonth = this.calendarMonth + 1;
      let missingDaysStart = [];
      let missingDaysEnd = [];
      let date = new Date();
      for (let index = 0; index < arrDaysStart.length; index++) {
        const element = arrDaysStart[index];
        date = new Date(this.calendarYear, this.calendarMonth, -element);
        missingDaysStart.push(date);
      }
      for (let index = 0; index < arrDaysEnd.length; index++) {
        const element = arrDaysEnd[index];
        date = new Date(this.calendarYear, targetNextMonth, index + 1);
        missingDaysEnd.push(date);
      }
      this.fillEmptyDays(missingDaysStart.reverse(), missingDaysEnd);
    },
    fillEmptyDays(arrStart, arrEnd) {
      let emptyDatesEndIdx = this.findEmptyRowFromCalendar();
      let firstEmptyValueFromArray = 0;
      if (emptyDatesEndIdx) {
        for (
          let index = 0;
          index < this.calendarDays[emptyDatesEndIdx].days.length;
          index++
        ) {
          const element = this.calendarDays[emptyDatesEndIdx].days[index];
          if (element == undefined) {
            firstEmptyValueFromArray = index;
            break;
          }
        }
      }
      for (let index = 0; index < arrStart.length; index++) {
        const element = arrStart[index];
        this.calendarDays[0].days.splice(index, 1, element);
      }
      for (let index = 0; index < arrEnd.length; index++) {
        const element = arrEnd[index];
        this.calendarDays[emptyDatesEndIdx].days.splice(
          index + firstEmptyValueFromArray,
          1,
          element
        );
      }
    },
    findEmptyRowFromCalendar() {
      let emptyRowIndex = undefined;
      for (let index = 1; index < this.calendarDays.length; index++) {
        const element = this.calendarDays[index].days;
        if (element.includes(undefined) && element[0] != undefined) {
          emptyRowIndex = index;
        }
      }
      return emptyRowIndex;
    },
  },
  mounted() {
    this.getDaysOfMonths(new Date().getMonth(), new Date().getFullYear());
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    currentMonth() {
      return new Date().getMonth();
    },
  },
  watch: {
    calendarMonth: function(val) {
      this.getDaysOfMonths(val, this.calendarYear);
    },
  },
};
</script>

<style></style>
